import { ILevel } from "../../models/user/level.model";
import { createReducer, on } from "@ngrx/store";
import * as LevelActions from '../actions/level.actions';

export const levelFeatureKey = 'LEVEL_STATE_KEY';

export interface LevelState {
  levels: ILevel[] | null
}

export const initialState: LevelState = {
  levels: null
}

export const levelReducer = createReducer(
  initialState,
  on(LevelActions.setLevels, (state, {levels}) => ({...state, levels}))
);
