import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class ExamScoreService {
  private readonly apiPrefix = '/client/exam-scores';

  constructor(
    private api: ApiService
  ) {
  }

  getScoreLessons(scoreId: number) {
    return this.api.get(`${this.apiPrefix}/${scoreId}/lessons`);
  }

  createScoreLesson(scoreId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${scoreId}/lessons`, data);
  }

  createInitial(data: object) {
    return this.api.post(`${this.apiPrefix}/initial`, data);
  }

  getInitial() {
    return this.api.get(`${this.apiPrefix}/initial`);
  }

  updateScoreLesson(scoreId: number, scoreLessonId: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${scoreId}/lessons/${scoreLessonId}`, data);
  }

  deleteScoreLesson(scoreId: number, scoreLessonId: number) {
    return this.api.delete(`${this.apiPrefix}/${scoreId}/lessons/${scoreLessonId}`);
  }

  getLatestScores() {
    return this.api.get(`${this.apiPrefix}/latest`);
  }

  getAverageScores() {
    return this.api.get(`${this.apiPrefix}/averages`);
  }

  createExamScores(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  setGoalScores(data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}/goals`, data);
  }

  setGoalLevels(data: object) {
    return this.api.post(`${this.apiPrefix}/goal-levels`, data);
  }

  getGoalScores() {
    return this.api.get(`${this.apiPrefix}/goals`);
  }

  getMonthlyAverageScores() {
    return this.api.get(`${this.apiPrefix}/monthly-average-scores`);
  }

  getMostWrongAnswerTopics(qs = '') {
    return this.api.get(`${this.apiPrefix}/most-wrong-answer-topics${qs}`);
  }
}
